import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import getAPI from "../app/api";

const API = getAPI({req: `users/all`});

const initialState = {
  user: {},
  loading: false,
};

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzI0OTMxNTkzLCJleHAiOjE3MjU1MzYzOTN9.VufCcMVBHglQKBbJ0js_KcDzK7obz9WCYhRKwAj0u3o';

export const fetchDataUser = createAsyncThunk("all/fetchDataUser", async () => {
    const res = await axios.get(API, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
    });
    return res.data.data
});

const userAllSlice = createSlice({
  name: "articlesH",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDataUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        state.loading = false;
      })
      .addCase(fetchDataUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userAllSlice.reducer;
