// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import BarChart from "components/charts/BarChart";
import React from "react";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from "variables/charts";
import { MdBarChart } from "react-icons/md";

export default function WeeklyRevenue(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  return (
    <Card align='center' direction='column' w='100%' {...rest}>
      {/* <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Weekly Revenue
        </Text>
        <Button
          align='center'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w='37px'
          h='37px'
          lineHeight='100%'
          borderRadius='10px'
          {...rest}>
          <Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
        </Button>
      </Flex> */}

      <Box h='330px' mt='auto'>
      <Text
          me='auto'
          color={textColor}
          fontSize='17'
          fontWeight='250'
          lineHeight='120%'
          textAlign="justify">
          OrthCVMS adalah aplikasi inovatif yang dirancang untuk menganalisis tahapan maturasi cervical vertebrae (C2, C3, dan C4) menggunakan foto rontgen cephalometrik. Aplikasi ini memanfaatkan teknologi pengolahan citra untuk mengidentifikasi dan menentukan titik-titik penting pada vertebra C2, C3, dan C4, yang kemudian digunakan untuk menghitung tahapan maturasi tulang. Dengan algoritma yang canggih, CVM Scan secara otomatis mengevaluasi bentuk morfologi vertebra dan memberikan hasil berupa klasifikasi tahapan maturasi. Hasil ini sangat berguna bagi para dokter ortodonti dan spesialis medis lainnya untuk merencanakan perawatan yang tepat berdasarkan tahap pertumbuhan tulang pasien, memungkinkan intervensi medis yang lebih efisien dan akurat.
        </Text>
      </Box>
    </Card>
  );
}
