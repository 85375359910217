import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import axios from "axios"
import getAPI from "../app/api";

const API = getAPI({req: `admin/login/email`});

const initialState = {
    isLoginPending: false,
    isLoginSuccess: false,
    errorMessage: '',
    token: {}
}

async function callLoginApi(Email, Password, navigation){
    try{
        const requestingData = {
            email: Email,
            password: Password
        }
        const response = await axios({
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: "POST",
            url: API,
            data: requestingData
        });
        // Alert.alert('Login Sukses', 'Selamat Datang!', [
        //     {text: 'OK', onPress: () => navigation.push("BottomTabBar")},
        // ]);
        return response

    } catch (error) {
        // Alert.alert('Login Gagal', 'Email atau Password salah, Cobalagi!', [
        //     {text: 'OK', onPress: () => console.log(error)},
        //   ]);
        throw error;
    }
}

export const authLoginApi = createAsyncThunk('login', async({userNameOrEmail, password, navigation}) => {
    try{
        const response = await callLoginApi(userNameOrEmail, password, navigation);
        return response.data.data.token
    }catch(err){
        throw(err)
    }
})

const authSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(authLoginApi.pending, (state) => {
            state.isLoginPending = true;
          })
          .addCase(authLoginApi.fulfilled, (state, { payload }) => {
            state.token = payload;
            state.isLoginPending = false;
            state.isLoginSuccess = true;
          })
          .addCase(authLoginApi.rejected, (state, action) => {
            state.isLoginPending = false;
            state.error = action.error.message;
          });
      },
})

export default authSlice.reducer