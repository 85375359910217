import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Text, Image } from "@chakra-ui/react";
import { useState, useEffect } from 'react';

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  const [logoData, setLogoData] = useState(null); // State untuk menyimpan data URL

  useEffect(() => {
    const logoPath = process.env.PUBLIC_URL + '/2.png'; // Path gambar logo
    fetch(logoPath)
      .then(res => res.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setLogoData(reader.result); // Simpan data URL di state
        };
        reader.readAsDataURL(blob); // Convert blob ke Data URL
      })
      .catch(err => {
        console.error('Error loading logo:', err);
      });
  }, []); // Jalankan hanya sekali ketika komponen mount

  return (
    <Flex align='center' direction='column'>
      <Image
          src={logoData} // Gunakan 'src' untuk gambar
          alt="Logo"
          boxSize={['200px']} // Ukuran responsif untuk mobile, tablet, dan desktop
          objectFit="contain" // Membuat gambar sesuai dengan kontainernya
          maxWidth="100%" // Pastikan gambar tidak melampaui lebar kontainer
        />
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
