/* eslint-disable */

import {
  Flex,
  Box,
  Image,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';

const columnHelper = createColumnHelper();

export default function CheckTable(props) {
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Informasi Penggunaan aplikasi WEB OrthCVMS
        </Text>
        <Menu />
      </Flex>
      <Box display="flex" justifyContent="center" alignItems="center" p={4}>
      <video width="100%" controls>
        <source src={`${process.env.PUBLIC_URL}/video/cvm.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
    </Card>
  );
}
